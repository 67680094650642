import React from "react";
import theme from "theme";
import { Theme, Text, Section, Box, Image, Button } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				Главная | GreenTurf Fields
			</title>
			<meta name={"description"} content={"Где каждый матч ощущается как домашняя игра"} />
			<meta property={"og:title"} content={"Главная | GreenTurf Fields"} />
			<meta property={"og:description"} content={"Где каждый матч ощущается как домашняя игра"} />
			<meta property={"og:image"} content={"https://quivurs.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://quivurs.com/img/9840655.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://quivurs.com/img/9840655.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://quivurs.com/img/9840655.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://quivurs.com/img/9840655.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://quivurs.com/img/9840655.png"} />
			<meta name={"msapplication-TileImage"} content={"https://quivurs.com/img/9840655.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="180px 0 184px 0" background="linear-gradient(180deg,rgba(11, 15, 59, 0.81) 0%,rgba(11, 15, 59, 0.7) 100%) 0 0 no-repeat,rgba(0, 0, 0, 0) url(https://quivurs.com/img/1.jpg) 0% 70% /cover repeat scroll padding-box" min-height="50vh" sm-padding="120px 0 124px 0" quarkly-title="Hero-15">
			<Text
				margin="0px 0px 30px 0px"
				text-align="center"
				color="--light"
				font="normal 400 18px/1.5 --fontFamily-sans"
				letter-spacing="1px"
			>
				Поля GreenTurf - запоминающиеся, а не просто голы
			</Text>
			<Text
				margin="0px 0px 50px 0px"
				text-align="center"
				font="normal 700 64px/1.2 --fontFamily-sans"
				letter-spacing="1px"
				color="#0d7d0d"
				padding="0px 250px 0px 250px"
				lg-padding="0px 0 0px 0"
				md-font="normal 700 42px/1.2 --fontFamily-sans"
				sm-font="normal 700 36px/1.2 --fontFamily-sans"
			>
				GreenTurf
			</Text>
			<Text
				margin="0px 0px 0px 0px"
				color="#c8ced8"
				text-align="center"
				padding="0px 260px 0px 260px"
				font="--lead"
				lg-padding="0px 0 0px 0"
			>
				Добро пожаловать на GreenTurf Fields - ваше поле для товарищеского матча, тренировки команды или просто посиделок с друзьями. Расположенные в самом сердце вашего города, мы предоставляем место, где страсть к футболу сочетается с общественным духом.
			</Text>
		</Section>
		<Section padding="80px 0 80px 0">
			<Box align-items="center" display="flex" justify-content="center" flex-direction="column">

				<Text
					margin="25px 0px 0px 0px"
					font="--lead"
					display="block"
					text-align="center"
					color="--darkL2"
					lg-width="100%"
				>
					Наше единственное, идеально ухоженное футбольное поле профессионального уровня оснащено всем необходимым для идеальной игры. Освещаемое прожекторами для вечерней игры и покрытое пышной, ухоженной травой, GreenTurf Fields предлагает первоклассные условия для игры. Здесь речь идет не просто об аренде поля, а о любви к игре.
				</Text>
			</Box>
		</Section>
		<Section padding="64px 0 64px 0" md-padding="36px 0 36px 0" lg-padding="48px 0 48px 0" quarkly-title="Images-20">
			<Override slot="SectionContent" max-width="960px" />
			<Text
				margin="0px 0px 50px 0px"
				font="--headline2"
				color="--dark"
				sm-font="normal 700 52px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
				text-align="center"
			>
				Почему стоит выбрать GreenTurf?
			</Text>
			<Box
				quarkly-title="Card"
				justify-content="space-between"
				sm-flex-direction="column"
				position="relative"
				lg-margin="0px 0px 64px 0px"
				sm-align-items="center"
				display="flex"
				align-items="center"
				margin="0px 0px 96px 0px"
			>
				<Image
					src="https://quivurs.com/img/2.jpg"
					object-fit="cover"
					width="45%"
					flex="0 0 auto"
					md-width="50%"
					sm-margin="0px 0px 24px 0px"
				/>
				<Text
					sm-margin="0px 0px 0px 0px"
					sm-text-align="left"
					margin="0px 0px 0px 8%"
					max-width="450px"
					md-margin="0px 0px 0px 24px"
					font="--lead"
				>
					Удачное расположение: Легкодоступное место с просторной парковкой.
					<br />
					<br />
					Качественное покрытие: Высококачественный газон для плавной игры.
					<br />
					<br />
					Ночная игра: Оснащен прожекторами для поздних игр.
					<br />
					<br />
					Эксклюзивное бронирование: Зарезервируйте все поле для своей группы.
					<br />
					<br />
					Доступные цены: Конкурентоспособные цены без скрытых платежей.
					<br /><br />

					<Button display="inline-block" href="/contacts" type="link" text-decoration-line="initial">
						Связаться
					</Button>
				</Text>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});